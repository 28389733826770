import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { postApplicationHoldDateFromNow } from "../../services/AdminDashService";
import { getConfigs } from "src/configs/Config";

const ResetHoldClockModal = (props) => {
  const { applicationHoldExtendTimeInMinutes } = getConfigs();
  const dispatch = useAppDispatch();
  const notification = useAppSelector(
    (state) => state.applications.notification
  );
  const [disableResetButton, setDisableResetButton] = useState(false);

  const { selectedApplication, onCancel, show, refreshApplicationData } = props;
  const cancelAction = () => {
    onCancel();
  };
  useEffect(() => {
    setDisableResetButton(false);
    if (
      notification &&
      notification.type === "INFO" &&
      notification?.message &&
      notification.message.endsWith("has been successfully reset")
    ) {
      refreshApplicationData();
      onCancel();
    } else {
      onCancel();
    }
  }, [notification]);

  const resetHoldClock = () => {
    setDisableResetButton(true);
    dispatch(
      postApplicationHoldDateFromNow(
        selectedApplication.applicationId,
        applicationHoldExtendTimeInMinutes
      )
    );
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      className="reset-hold-clock-modal"
    >
      <Modal.Header>
        <Modal.Title>
          <span className="cancel-icon">!</span>Reset the hold clock?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="details">
          <div className="row">
            <div className="col">
              The clock will be reset to 24 hrs. This is the only time you will
              be able to reset the clock, would you like to proceed?
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={cancelAction}
          disabled={disableResetButton}
        >
          Cancel
        </Button>
        <Button
          variant="dark"
          onClick={resetHoldClock}
          disabled={disableResetButton}
        >
          Reset
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ResetHoldClockModal;
