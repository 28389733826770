import { getConfigs } from "../configs/Config";
import { fetchIdToken, fetchRefreshToken } from "../utils/Auth";

const getToken = async (user) => {
  const { apiKey, baseRoute } = getConfigs();

  const requestOptions = {
    method: "POST",
    headers: new Headers({
      "content-type": "application/json",
      "x-api-key": apiKey || "",
    }),
    body: JSON.stringify({
      emailAddress: user.email,
      password: user.password,
    }),
  };

  const url = `${baseRoute}/authenticate`;
  const resp = await fetch(url, requestOptions);
  return resp;
};

const getTokensFromAuthorizationCode = async (code) => {
  const { apiKey, baseRoute } = getConfigs();

  const requestOptions = {
    method: "POST",
    headers: new Headers({
      "content-type": "application/json",
      "x-api-key": apiKey || "",
    }),
    body: JSON.stringify({
      code: code,
    }),
  };

  const url = `${baseRoute}/credentials`;
  const resp = await fetch(url, requestOptions);
  return resp;
};

const refreshToken = async () => {
  const { apiKey, baseRoute } = getConfigs();

  const requestOptions = {
    method: "POST",
    headers: new Headers({
      "content-type": "application/json",
      "x-api-key": apiKey || "",
      Authorization: fetchIdToken() || "",
    }),
    body: JSON.stringify({
      refreshToken: fetchRefreshToken(),
    }),
  };

  const url = `${baseRoute}/refresh-token`;
  const resp = await fetch(url, requestOptions);
  return resp;
};

const AuthService = {
  getToken,
  getTokensFromAuthorizationCode,
  refreshToken,
};

export default AuthService;
