import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { storeCredentialsFromCallback } from "../../utils/Auth";
import AuthService from "../../services/AuthService";

/**
 * Helper function for removing first character.
 * Note: We can't use replace since the character may be used else where
 */
const removeFirstCharacter = (str) => {
  const arr = str.split("");
  arr.shift();
  return arr.join("");
};

/**
 * Helper function for getting the parameters from either a hash or search
 * Note: Only a search query '?' has a default function for fetching entries.
 *       Hashes '#' don't.
 */
const getPayload = (source) => {
  const params = removeFirstCharacter(source);
  const pairs = params.split("&");
  const keyVals = {};

  pairs.forEach((p) => {
    const [key, value] = p.split("=");
    keyVals[key] = value;
  });

  return keyVals;
};

const CognitoRedirectUri = () => {
  const location = useLocation();
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isSuccess, setSuccess] = useState(false);
  const { hash, search } = location;

  useEffect(() => {
    if (isLoading) {
      AuthService.getTokensFromAuthorizationCode(payload["code"])
        .then((response) => response.json())
        .then((response) => {
          // 400 response
          if (!!response["error"]) {
            console.log("Error", response);
            setError(true);
            setLoading(false);
            return;
          }

          // 200 successful
          storeCredentialsFromCallback(response);
          setSuccess(true);
          setLoading(false);
        })
        .catch((err) => {
          console.log("Error", err);
          setError(true);
          setLoading(false);
        });
    }
  }, [isLoading]);

  // no credentials found
  if (!hash && !search) {
    return <Navigate to="/AdminDashboard/Login" />;
  }

  // get key/value
  const source = hash || search;
  const payload = getPayload(source);

  if (!payload["code"]) {
    return <Navigate to="/AdminDashboard/Login" />;
  }

  return isLoading ? (
    <div>Redirecting...</div>
  ) : isError ? (
    <Navigate to="/AdminDashboard/Login" />
  ) : isSuccess ? (
    payload["state"] &&
    payload["state"].toLowerCase().startsWith("/admindashboard/") ? (
      <Navigate to={payload["state"]} />
    ) : (
      <Navigate to="/AdminDashboard/Applications" />
    )
  ) : (
    <Navigate to="/AdminDashboard/Login" />
  );
};

export default CognitoRedirectUri;
