import {
  Applicant,
  ApplicantScreening,
  ScreeningType,
  ScreeningVendor,
} from "src/types/Applicant";
import { convertToLocalAndFormatDate, formatTime } from "./Utils";
import {
  Issue,
  MapScreeningEnumTypeToScreeningStatusId,
  ScreeningDocumentResult,
  ScreeningStatus,
} from "src/types/Screening";

/**
 * Helper function for sorting dates w/ consideration
 *      over the createdOn and modifiedDate
 */
const sortByDateDesc = (a, b) => {
  const dateA = new Date(a.modifiedDate || a.createdOn);
  const dateB = new Date(b.modifiedDate || b.createdOn);

  if (dateA.getTime() > dateB.getTime()) {
    return -1;
  }
  if (dateA.getTime() < dateB.getTime()) {
    return 1;
  }
  return 0;
};

const mapScreeningDocumentResult = (
  screeningStatusId: string,
  noOfAttempts: number
): string => {
  switch (screeningStatusId) {
    case "Approved":
      if (noOfAttempts > 0) {
        return ScreeningDocumentResult.resubmittedClean;
      }
      return ScreeningDocumentResult.clean;
    case "Retry":
      if (noOfAttempts > 0) {
        return ScreeningDocumentResult.resubmittedInsufficient;
      }
      return ScreeningDocumentResult.insufficientDocumentation;
    case "Declined":
      if (noOfAttempts > 0) {
        return ScreeningDocumentResult.resubmittedEdited;
      }
      return ScreeningDocumentResult.edited;
    default:
      return "-";
  }
};

/**
 * Helper function for exposing the onfido and fadv screening status,
 *  as well as the most recent update dated.
 */

export interface ScreeningInfo {
  lastUpdated: string;
  screeningStatus: string;
  screeningVendor: string;
  issues?: Issue[];
}

interface ScreeningStatuses {
  [key: string]: any;
  onfido?: ScreeningInfo;
  fadv?: ScreeningInfo;
}
export const getScreeningStatuses = (
  [...applicantScreening]: ApplicantScreening[],
  setLatestDate,
  isScreeningFlowEnabled = false
): ScreeningStatuses | undefined => {
  const hasApplicantScreeningData =
    applicantScreening && applicantScreening.length;

  const statuses: ScreeningStatuses = {};
  let latestDate: string | null = null;

  if (hasApplicantScreeningData) {
    // sort descending by dates
    applicantScreening.sort(sortByDateDesc);

    // since this is sorted in descending order
    // the most updated dates should be available first
    applicantScreening.sort(sortByDateDesc).forEach((screening, idx) => {
      const {
        screeningVendor,
        createdOn,
        modifiedDate,
        isSoftDeleted,
        issues,
        screeningType,
        noOfAttempts,
      } = screening;
      let { screeningStatus } = screening;

      const isComplete = !!statuses["onfido"] && !!statuses["fadv"];

      // if no vendor or already complete, end fast
      if (!screeningVendor || isComplete || isSoftDeleted) return;

      // capture latest date
      if (idx === 0) {
        latestDate = modifiedDate || createdOn;
      }

      // For Identity screening:
      // when applicant is declined for first attempt, applicant is allowed to retry once, so status should render as Retry
      // when applicant opens onfido window for 2nd attempt, screening status lands in "Other" status, but status should render as Retry

      if (screeningType === ScreeningType.IDENTITY) {
        if (
          screeningStatus === ScreeningStatus.Declined &&
          noOfAttempts === 0
        ) {
          screeningStatus = ScreeningStatus.Retry;
        } else if (
          screeningStatus === ScreeningStatus.Other &&
          noOfAttempts > 0
        ) {
          screeningStatus = ScreeningStatus.Retry;
        }
      }

      // format
      const vendor = screeningVendor.toLowerCase();
      statuses[vendor] = {
        lastUpdated: modifiedDate || createdOn,
        screeningStatus: isScreeningFlowEnabled
          ? MapScreeningEnumTypeToScreeningStatusId[screeningStatus]
          : screeningStatus,
        screeningVendor,
        issues,
      };
    });

    statuses["latestDate"] = latestDate;
    if (latestDate) {
      const formattedDate = `${formatTime(
        latestDate
      )}, ${convertToLocalAndFormatDate(latestDate)}`;
      setLatestDate(formattedDate);
    }
    return statuses;
  }
};

export const getDocumentScreeningData = (
  applicant: Applicant,
  [...applicantScreening]: ApplicantScreening[]
) => {
  const hasApplicantScreeningData =
    applicantScreening && applicantScreening.length;
  let documentScreening = {
    emailAddress: applicant.emailAddress,
    firstName: applicant.firstName,
    lastName: applicant.lastName,
    snapptStatus: "Not Started",
    result: "",
    issues: "",
    documents: "",
    lastUpdated: "",
    id: applicant.id,
    isSoftDelete: applicant.isSoftDelete,
  };
  if (hasApplicantScreeningData) {
    // get latest snappt screening
    const snapptScreening = applicantScreening
      .sort(sortByDateDesc)
      .filter(
        (screening) => screening.screeningVendor === ScreeningVendor.SNAPPT
      );

    if (snapptScreening.length) {
      documentScreening = {
        emailAddress: applicant.emailAddress,
        firstName: applicant.firstName,
        lastName: applicant.lastName,
        snapptStatus:
          snapptScreening[0]?.screeningStatus !== "Pending"
            ? "Ready"
            : "Pending",
        result: mapScreeningDocumentResult(
          snapptScreening[0]?.screeningStatus,
          snapptScreening[0].noOfAttempts
        ),
        issues:
          snapptScreening[0]?.issues.length > 0
            ? snapptScreening[0]?.issues[0].description
            : "",
        documents:
          snapptScreening[0]?.noOfAttempts != null
            ? (snapptScreening[0]?.noOfAttempts * 2 + 2).toString()
            : "-",
        lastUpdated:
          snapptScreening.length > 0
            ? snapptScreening[0].modifiedDate ?? snapptScreening[0].createdOn
            : "-",
        id: applicant.id,
        isSoftDelete: applicant.isSoftDelete,
      };
    }
  }
  return documentScreening;
};
