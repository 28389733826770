import { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { submitCreditScreening } from "../../services/AdminDashService";

const SubmitCreditScreeningModal = (props) => {
  const dispatch = useAppDispatch();
  const notification = useAppSelector(
    (state) => state.applications.notification
  );
  const { showModal, setShowModal, applicant, applicationId } = props;
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (
      notification &&
      notification.type === "INFO" &&
      notification.message?.startsWith("Credit Screening")
    ) {
      window.localStorage.setItem("REFRESH_APPS", Math.random().toString());
      resetModal();
      window.scrollTo(0, 0);
    } else if (
      notification &&
      notification.type === "ERROR" &&
      notification.message
    ) {
      resetModal();
    }
  }, [notification]);

  const runCredit = () => {
    setDisableSubmit(true);
    setShowSpinner(true);
    dispatch(
      submitCreditScreening(
        applicationId,
        applicant.id,
        `${applicant.firstName} ${applicant.lastName}`
      )
    );
  };

  const resetModal = () => {
    setShowModal(false);
    setShowSpinner(false);
    setDisableSubmit(false);
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showModal}
      className="submit-credit-modal"
    >
      <Modal.Header className="border-bottom">
        <Modal.Title>Submit Credit Screening</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="details block-labels mt-0">
          <div className="subtitle">
            Do you want to submit the credit screening again?
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-top">
        <Button
          className="cancel-btn"
          variant="secondary"
          onClick={() => setShowModal(false)}
          disabled={disableSubmit}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => runCredit()}
          disabled={disableSubmit}
        >
          {showSpinner && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="me-2"
            />
          )}

          {showSpinner ? "Loading..." : "Run Credit Screening"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SubmitCreditScreeningModal;
