import { Chip, Tooltip, tooltipClasses } from "@mui/material";
import { Collapse, Table } from "antd";
import { useEffect, useState } from "react";
import DocumentResultChip from "src/components/Shared/DocumentResultChip";
import { convertToLocalAndFormatDate, formatTime } from "src/utils/Utils";
import { ReduxApplication } from "src/types/Application";
import { getDocumentScreeningData } from "src/utils/Screening";
import { ScreeningDocumentResult } from "src/types/Screening";

interface DocumentScreeningProps {
  application: ReduxApplication;
}

const getPanelTitle = (result: string) => {
  return (
    <>
      SNAPPT Document Validation
      {result && <DocumentResultChip result={result} issue="" id="" />}
    </>
  );
};

const getScreeningDocumentStatus = (applicants: any[]) => {
  if (
    applicants.findIndex(
      (applicant) =>
        applicant?.snapptStatus === "Pending" ||
        (applicant?.result ===
          ScreeningDocumentResult.pendingDocumentationVerification &&
          !applicant?.isSoftDelete)
    ) > -1
  ) {
    return ScreeningDocumentResult.pendingDocumentationVerification;
  }
  if (
    applicants.find(
      (applicant) =>
        applicant?.result ===
          ScreeningDocumentResult.insufficientDocumentation ||
        (applicant?.result ===
          ScreeningDocumentResult.resubmittedInsufficient &&
          !applicant?.isSoftDelete)
    )
  ) {
    return ScreeningDocumentResult.insufficientDocumentation;
  }
  if (
    applicants.find(
      (applicant) =>
        applicant?.result === ScreeningDocumentResult.edited ||
        (applicant?.result === ScreeningDocumentResult.resubmittedEdited &&
          !applicant?.isSoftDelete)
    )
  ) {
    return ScreeningDocumentResult?.edited;
  }
  if (
    applicants.find(
      (applicant) =>
        applicant?.result === ScreeningDocumentResult.resubmittedClean ||
        (applicant?.result === ScreeningDocumentResult.clean &&
          !applicant?.isSoftDelete)
    )
  ) {
    return ScreeningDocumentResult.clean;
  }
  return ScreeningDocumentResult.pendingDocumentationVerification;
};

const componentsProps = {
  popper: {
    sx: {
      [`& .${tooltipClasses.arrow}`]: {
        color: (theme) => theme.palette.common.white,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: (theme) => theme.palette.common.white,
        color: (theme) => theme.palette.common.black,
        boxShadow: 1,
      },
    },
  },
};

const DocumentScreening = ({ application }: DocumentScreeningProps) => {
  const { Panel } = Collapse;
  const [documentationData, setDocumentationData] = useState([]);
  const [panelTitle, setPanelTitle] = useState("");

  useEffect(() => {
    const formattedApplicantData = application?.applicants.map((applicant) => {
      return getDocumentScreeningData(applicant, applicant.applicantScreening);
    });
    prepareApplicantsData(formattedApplicantData);
    setPanelTitle(getScreeningDocumentStatus(formattedApplicantData));
  }, [application]);

  const prepareApplicantsData = (applicants: any[]) => {
    const applicantsData: any = [];
    applicants.forEach((applicant) => {
      const formattedDate = applicant?.lastUpdated
        ? `${formatTime(applicant?.lastUpdated)}, ${convertToLocalAndFormatDate(
            applicant?.lastUpdated
          )}`
        : "-";

      const data = {
        fullName: (
          <Tooltip
            title={applicant?.emailAddress}
            key={applicant?.id}
            arrow
            placement="top"
            componentsProps={componentsProps}
          >
            <span>
              {`${applicant?.firstName || ""} ${applicant?.lastName || ""} `}
              {applicant?.isSoftDelete ? (
                <Chip color="warning" label="Removed" size="small" />
              ) : (
                ""
              )}
            </span>
          </Tooltip>
        ),
        reportStatus: applicant?.snapptStatus,
        result:
          applicant?.result != "-" ? (
            <Tooltip
              title={applicant?.emailAddress}
              key={applicant?.id}
              arrow
              placement="top"
              componentsProps={componentsProps}
            >
              <DocumentResultChip
                result={applicant?.result}
                issue={applicant?.issue}
                id={applicant?.id}
              />
            </Tooltip>
          ) : (
            "-"
          ),
        documents: applicant?.documents ?? "-",
        receivedDate: formattedDate,
      };
      applicantsData.push(data);
    });
    setDocumentationData(applicantsData);
  };

  return (
    <Collapse defaultActiveKey={"3"} bordered={false}>
      <Panel header={getPanelTitle(panelTitle)} key="3">
        <div className=" col-sm-12 col-md-12">
          <Table
            dataSource={documentationData}
            columns={[
              {
                title: "Applicants",
                dataIndex: "fullName",
                key: "fullName",
              },
              {
                title: "Report Status",
                dataIndex: "reportStatus",
                key: "reportStatus",
              },
              {
                title: "Result",
                dataIndex: "result",
                key: "result",
              },
              {
                title: "Documents",
                dataIndex: "documents",
                key: "documents",
              },
              {
                title: "Received",
                dataIndex: "receivedDate",
                key: "receivedDate",
              },
            ]}
            pagination={false}
          />
        </div>
      </Panel>
    </Collapse>
  );
};

export default DocumentScreening;
