import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  ApplicationStatusId,
  ApplicationStatusText,
  ReduxApplication,
} from "../types/Application";
import { Applicant } from "src/types/Applicant";

dayjs.extend(utc);

const APPLICATION_STATUSES = [
  { id: 0, appStatus: "Invite Sent" },
  { id: 1, appStatus: "In Progress" },
  { id: 2, appStatus: "Rejected" },
  { id: 3, appStatus: "Completed" },
  { id: 4, appStatus: "Removed" },
  { id: 5, appStatus: "Credit and ID Returned" },
  { id: 6, appStatus: "Lease Pending" },
  { id: 7, appStatus: "Lease Signed" },
  { id: 8, appStatus: "Credit and ID Pending" },
  { id: 9, appStatus: "Cancelled" },
  { id: 10, appStatus: "Approved" },
  { id: 11, appStatus: "Closed" },
  { id: 12, appStatus: "Action Required" },
  { id: 13, appStatus: "Application Complete" },
  { id: 14, appStatus: "Lease Ready" },
  { id: 15, appStatus: "Document Expired" },
  { id: 16, appStatus: "Lease Declined" },
];

const capitalize = (sentence) => {
  if (!sentence) return;
  const words = sentence.toLowerCase().split(" ");
  return words
    .map((word) => {
      if (!word.length) return "";
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");
};

const formatDate = (date) => {
  if (!date) return;
  return dayjs.utc(date).format("M/D/YYYY");
};

const formatTime = (date) => {
  return dayjs(date).format("h:mma");
};

const convertToLocalAndFormatDate = (date) => {
  if (!date) return;
  return dayjs(date).format("M/D/YYYY");
};

const formatUnit = (unitId) => {
  const unitArray = unitId.split("-");
  return unitArray.pop();
};

const filterBySearch = (apps, searchTerms) => {
  if (searchTerms === undefined || searchTerms === "" || searchTerms === null)
    return apps;
  const searchTermsCaps = searchTerms.trim().toUpperCase();

  return apps.filter((app) => {
    const completeUnitId = `${app.communityId}-${app.phaseId}-${app.buildingId}-${app.unitId}`;

    const unitIdMatch =
      completeUnitId && completeUnitId.toUpperCase().includes(searchTermsCaps);
    const primaryName =
      `${app.primaryApplicantFirstName} ${app.primaryApplicantLastName}`.toUpperCase();
    const primaryNameMatch = primaryName.includes(searchTermsCaps);
    const secondaryNameMatch =
      app.applicants &&
      app.applicants.some((applicant) => {
        const secondaryName =
          `${applicant.firstName} ${applicant.lastName}`.toUpperCase();
        return secondaryName.includes(searchTermsCaps);
      });
    return unitIdMatch || primaryNameMatch || secondaryNameMatch;
  });
};

const filterBySearchAndAction = (apps, searchTerms, actionRequiredId) => {
  const translateActionId = {
    "avb-actions-total": "totalActionsRequired",
    "avb-actions-help-requests": "helpRequestCount",
    "avb-actions-inc-stale-items": "incStaleItemsCount",
    "avb-actions-ready-to-finish": "readyToFinishCount",
  };
  const actionPropName = translateActionId[actionRequiredId];

  // if no applications available
  if (!apps) return [];

  const filteredBySearch = filterBySearch(apps, searchTerms);
  const filteredByAction = filteredBySearch.filter((app) => {
    if (actionRequiredId === "avb-actions-total") {
      return (
        app["helpRequestCount"] > 0 ||
        app["incStaleItemsCount"] ||
        app["readyToFinishCount"] > 0
      );
    } else {
      return app[actionPropName] > 0;
    }
  });
  return actionRequiredId ? filteredByAction : filteredBySearch;
};

const getHelpRequestsSum = (applications: ReduxApplication[]): number => {
  const totalHelpRequestsCount = applications.reduce(
    (prev, curr) => prev + (curr?.helpRequestCount || 0),
    0
  );
  return totalHelpRequestsCount;
};

const getGenderEquivalent = (gender) => {
  switch (gender) {
    case "F":
      return "Female";
    case "M":
      return "Male";
    case "B":
      return "Non-Binary";
    case "T":
      return "Transgender";
    case "D":
      return "Prefer Not to Say";
    case "O":
      return "Other";
    default:
      return "Other";
  }
};

const getIncStaleItemsSum = (applications) => {
  return applications.reduce((prev, curr) => {
    return prev + (curr.incStaleItemsCount ? 1 : 0);
  }, 0);
};

const getReadyToFinishSum = (applications) => {
  return applications.reduce((prev, curr) => {
    return prev + (curr.readyToFinishCount ? 1 : 0);
  }, 0);
};

const formatSSN = (ssn) => {
  if (!ssn) return;
  const split = ssn.split("");
  return "*** ** " + split.slice(split.length - 4).join("");
};

const getStatusName = (statusId) => {
  const status = APPLICATION_STATUSES.find((status) => statusId === status.id);
  return status ? status.appStatus : "";
};

/**
 * Receives a lease status id and returns the status string name.
 * @param {number} leaseStatusId
 * @returns Status string name
 */
const getLeaseStatusName = () => {
  return "Pending Signature";
};

const formatPhone = (phone) => {
  if (!phone) return;

  // remove extra characters
  const regEx = /[\(\)\-\s]*/g; // matches '(', ')', space and '-'
  phone = phone.replace(regEx, "");

  const areaCode = phone.slice(0, 3);
  const firstThree = phone.slice(3, 6);
  const lastFour = phone.slice(6);
  return `(${areaCode}) ${firstThree}-${lastFour}`;
};

const getLatestDate = (app) => {
  const dates: string[] = [];

  app.applicants.forEach((applicant) => {
    if (applicant.applicationProcess) {
      if (applicant.applicationProcess.modifiedDate)
        dates.push(applicant.applicationProcess.modifiedDate);
      if (applicant.applicationProcess.createdDate)
        dates.push(applicant.applicationProcess.createdDate);
    }
  });

  if (dates.length) {
    dates.sort((a, b) => Date.parse(b) - Date.parse(a));
    return dates[0];
  }

  return null;
};

const applicationStatusLogicalOrder: { [id: number]: number } = {
  [ApplicationStatusId.InviteSent]: 9,
  [ApplicationStatusId.InProgress]: 10,
  [ApplicationStatusId.Rejected]: 11,
  [ApplicationStatusId.Completed]: 12,
  [ApplicationStatusId.Removed]: 13,
  [ApplicationStatusId.CreditAndIDReturned]: 16,
  [ApplicationStatusId.LeasePending]: 20,
  [ApplicationStatusId.LeaseSigned]: 21,
  [ApplicationStatusId.CreditAndIDPending]: 14,
  [ApplicationStatusId.Cancelled]: 22,
  [ApplicationStatusId.Approved]: 23,
  [ApplicationStatusId.Closed]: 24,
  [ApplicationStatusId.ActionRequired]: 15,
  [ApplicationStatusId.ApplicationComplete]: 25,
  [ApplicationStatusId.LeaseReady]: 19,
  [ApplicationStatusId.LeaseExpired]: 17,
  [ApplicationStatusId.LeaseDeclined]: 18,
};
// From Legacy--->
// private static string GetApplicationStatus(ICollection<DAL.Models.ApplicationProcess> applicationProcess) =>
//   applicationProcess
//       .Where(x => !x.IsSoftDelete)
//       .OrderBy(x => x.ApplicationStatus.LogicalOrder)
//       .FirstOrDefault()
//       ?.ApplicationStatus?.AppStatus ?? "None";
//
const getApplicationProcessStatusId = (app: ReduxApplication) => {
  const applicant = app.applicants.reduce(
    (prev, cur) =>
      prev === undefined ||
      applicationStatusLogicalOrder[
        prev.applicationProcess.applicationStatusId
      ] >
        applicationStatusLogicalOrder[
          cur.applicationProcess.applicationStatusId
        ]
        ? cur
        : prev,
    <Applicant | undefined>undefined
  );
  return applicant?.applicationProcess?.applicationStatusId;
};

const addFullNameAndlastUpdated = ([...applications]: ReduxApplication[]) => {
  let apps = applications.map(({ ...x }) => {
    let lastUpdated;
    const applicationProcessStatusId = getApplicationProcessStatusId(x);
    if (
      applicationProcessStatusId === ApplicationStatusId.CreditAndIDReturned
    ) {
      lastUpdated = getLatestDate(x);
    } else {
      lastUpdated = x.modifiedDate;
    }

    if (!x.primaryApplicantFirstName) x.primaryApplicantFirstName = "";
    if (!x.primaryApplicantLastName) x.primaryApplicantLastName = "";
    return {
      ...x,
      fullName: `${x.primaryApplicantFirstName} ${x.primaryApplicantLastName}`,
      lastUpdated,
      applicationProcessStatusId,
      applicationProcessStatusText: APPLICATION_STATUSES.find(
        (item) => item.id === applicationProcessStatusId
      )?.appStatus,
    };
  });
  return apps;
};

/**
 * Helper function for creating chunks or arrays
 *      from based on the size.
 * For example chunk([1,2,3,4], 2)
 *             [[1,2],[3,4]]
 * @param {*} arr
 * @param {*} size
 * @returns
 */
const chunk = (arr, size) => {
  return arr.reduce((chunks, item, index) => {
    return (
      index % size ? chunks[chunks.length - 1].push(item) : chunks.push([item]),
      chunks
    );
  }, []);
};

const formatCurrency = (amount) => {
  if (amount == null) return;

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return formatter.format(amount);
};

const isApplicationEditable = (applicant) => {
  return (
    applicant.emailAddress &&
    (applicant.nonUSPhone == true ||
      (applicant.phoneNumber && applicant.phoneNumberType)) &&
    applicant.status !== ApplicationStatusText.INVITE_SENT
  );
};

export {
  capitalize,
  convertToLocalAndFormatDate,
  chunk,
  formatDate,
  formatTime,
  formatUnit,
  filterBySearch,
  filterBySearchAndAction,
  getGenderEquivalent,
  getHelpRequestsSum,
  getReadyToFinishSum,
  getIncStaleItemsSum,
  formatSSN,
  getStatusName,
  getLeaseStatusName,
  formatPhone,
  addFullNameAndlastUpdated,
  formatCurrency,
  isApplicationEditable,
};
