import ApplicationList from "../AdminDashboard/ApplicationList";
import { Filters } from "../AdminDashboard/Filters";
import ActionRequired from "../AdminDashboard/ActionRequired";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { Alert } from "react-bootstrap";
import {
  resetNotification,
  setApplications,
} from "src/store/features/applications/applicationsSlice";
import WarningModal from "../Shared/WarningModal";
import LastFetchedDate from "../Shared/LastFetchedDate";
import { filterCommunities, getPhases } from "src/utils/Filter";
import { getApplications } from "src/services/AdminDashService";
import { GetFilterOptionsBody } from "src/types/FilterOptions";

const AdminDashboard = () => {
  const notification = useAppSelector(
    (state) => state.applications.notification
  );
  const filterOptions = useAppSelector(
    (state) => state.applications.filterOptions
  );
  const lastFetchedDate = useAppSelector(
    (state) => state.applications.lastFetchedDate
  );

  const dispatch = useAppDispatch();
  const closeNotification = () => {
    dispatch(resetNotification());
  };

  const onRefreshApplications = () => {
    const { userFilterSelections } = filterOptions as GetFilterOptionsBody;
    const isNonFirstTimeVisitor =
      filterOptions && userFilterSelections && !!userFilterSelections?.id;
    const hasCommunitiesSelections = !!userFilterSelections.communities;
    const hasMarketSelections = !!userFilterSelections.markets;
    const hasRegionSelections = !!userFilterSelections.regions;

    const hasNoSelections =
      !hasCommunitiesSelections && !hasMarketSelections && !hasRegionSelections;

    // ignore first-time visitor w/o selections
    if (!isNonFirstTimeVisitor && hasNoSelections) return;

    if (hasNoSelections) {
      dispatch(setApplications({ applications: [], count: 0 }));

      return;
    }

    if (hasCommunitiesSelections) {
      const communitySelections = userFilterSelections.communities.split(",");
      const phases = getPhases(filterOptions, communitySelections);

      if (phases.length > 0) dispatch(getApplications(phases));
      else dispatch(setApplications({ applications: [], count: 0 }));

      return;
    }

    if (hasMarketSelections || hasRegionSelections) {
      const communities = filterCommunities(
        filterOptions as GetFilterOptionsBody
      );
      const communityValues = communities.map((x) => x.value);
      const phases = getPhases(filterOptions, communityValues);

      if (phases.length > 0) dispatch(getApplications(phases));
      else dispatch(setApplications({ applications: [], count: 0 }));

      return;
    }
  };

  return (
    <div id="avb-admin-dashboard">
      <div className="admin-header">
        <Header email="" />
      </div>
      <div className="admin-body">
        <LastFetchedDate
          date={lastFetchedDate ?? ""}
          onRefresh={onRefreshApplications}
        />
        <ActionRequired />
        <Filters />
        {notification && notification.type === "INFO" && (
          <Alert variant="success" onClose={closeNotification} dismissible>
            {notification.message}
          </Alert>
        )}
        <ApplicationList />
        <WarningModal />
        <Footer />
      </div>
    </div>
  );
};
export default AdminDashboard;
