import { Collapse, Table } from "antd";
import { useEffect, useState } from "react";
import { useAppSelector } from "src/hooks/redux";
import { convertToLocalAndFormatDate, formatTime } from "../../utils/Utils";
import SubmitAgainIcon from "../Shared/icons/SubmitAgainIcon";
import DollarIcon from "../Shared/icons/DollarIcon";
import SubmitCreditScreeningModal from "./SubmitCreditScreeningModal";
import ConfirmIncreasedDepositModal from "./ConfirmIncreasedDepositModal";
import StatusTag from "../Shared/StatusTag";
import {
  Applicant,
  ApplicantType,
  ScreeningVendor,
} from "../../types/Applicant";
import {
  ApplicationStage,
  ApplicationStatusId,
  ReduxApplication,
} from "../../types/Application";
import { ScreeningInfo, getScreeningStatuses } from "src/utils/Screening";
import { ScreeningStatusMap, ScreeningTypes } from "src/types/Screening";
import Chip from "@mui/material/Chip";

interface ScreeningProps {
  application: ReduxApplication;
  isScreeningFlowEnabled?: boolean | false;
}

interface ApplicantExtendedScreening extends Applicant {
  onfidoStatus?: string;
  fadvStatus?: string;
  lastUpdated?: string;
  issues?: { [key: string]: any };
}

const Screening = ({ application, isScreeningFlowEnabled }: ScreeningProps) => {
  const [applicantData, setApplicantData] = useState([]);
  const [guarantorData, setGuarantorData] = useState([]);
  const notification = useAppSelector(
    (state) => state.applications.notification
  );
  const [formattedApplication, setFormattedApplication] = useState<
    ReduxApplication | undefined
  >();
  const [applicantColumns, setApplicantColumns] = useState([]);
  const [guarantorColumns, setGuarantorColumns] = useState([]);
  const { Panel } = Collapse;
  const [latestDate, setLatestDate] = useState("");
  const [showCreditScreeningModal, setShowCreditScreeningModal] =
    useState(false);
  const [
    showConfirmIncreasedDepositModal,
    setShowConfirmIncreasedDepositModal,
  ] = useState(false);
  const [hasScreeningAlerts, setHasScreeningAlerts] = useState(false);

  useEffect(() => {
    application && prepareApplicationData(application);
  }, [application]);

  const prepareApplicationData = (app: ReduxApplication): void => {
    const formattedApplicants = [...app.applicants].map(
      ({ ...applicant }: ApplicantExtendedScreening) => {
        const { applicantScreening } = applicant;
        const screeningStatuses = getScreeningStatuses(
          applicantScreening,
          setLatestDate,
          isScreeningFlowEnabled
        );
        let onfidoStatus: string | null = null;
        let fadvStatus: string | null = null;

        if (!screeningStatuses) return applicant;
        const applicantIssues: any = {};
        Object.values<ScreeningInfo>(screeningStatuses).forEach((screening) => {
          if (screening.issues) {
            screening.issues.map((issue) => {
              if (!applicantIssues[issue.type]) {
                applicantIssues[issue.type] = [issue];
              } else {
                applicantIssues[issue.type].push(issue);
              }
            });
          }
        });

        const ScreeningStatuses = {
          Other: "Not Started",
          Pending: "Pending",
          Approved: "Approved",
          ApprovedWithConditions: "Approved With Conditions",
          ApprovedWithConditionsMet: "AWC, conditions met",
          Retry: "Retry",
          FurtherReview: "Further Review",
          ProblemResolution: "Problem Resolution",
          Declined: "Declined",
          Cancelled: "Cancelled",
        };
        if (!!screeningStatuses["onfido"]) {
          onfidoStatus =
            ScreeningStatuses[screeningStatuses["onfido"].screeningStatus] ??
            screeningStatuses["onfido"].screeningStatus;
        }

        if (!!screeningStatuses["fadv"]) {
          fadvStatus =
            ScreeningStatuses[screeningStatuses["fadv"].screeningStatus] ??
            screeningStatuses["fadv"].screeningStatus;
        }
        if (Object.keys(applicantIssues).length > 0) {
          setHasScreeningAlerts(true);
        }
        applicant.onfidoStatus = onfidoStatus || "Not Started"; // action - credit screening outcome
        applicant.fadvStatus = fadvStatus || ""; // status - id screening outcome
        applicant.lastUpdated = screeningStatuses["latestDate"]
          ? screeningStatuses["latestDate"]
          : "";
        applicant.issues = Object.keys(applicantIssues).length
          ? applicantIssues
          : null;
        return applicant;
      }
    );

    setFormattedApplication({
      ...app,
      applicants: formattedApplicants as Applicant[],
    });
    prepareApplicantsAndGuarantorsData(
      formattedApplicants as ApplicantExtendedScreening[]
    );
  };

  const prepColumns = (title: string, itemCount: number) => {
    const col: any = [
      {
        title: `${title} (${itemCount})`,
        dataIndex: "fullName",
        key: "fullName",
        width: "25%",
      },
      {
        title: "Credit Screening Status",
        dataIndex: "credit",
        key: "credit",
      },
      {
        title: "Alerts",
        dataIndex: "alerts",
        key: "alerts",
      },
      {
        title: "ID Fraud Status",
        dataIndex: "identity",
        key: "identity",
      },
      {
        title: "Last Update",
        dataIndex: "modifiedDate",
        key: "modifiedDate",
      },
    ];

    if (title === "Adults") setApplicantColumns(col);
    else if (title === "Guarantors") setGuarantorColumns(col);
  };

  const prepareApplicantsAndGuarantorsData = (
    applicants: ApplicantExtendedScreening[]
  ) => {
    const arr: any = [];
    const guarantors: any = [];
    const len = applicants.length;
    for (let i = 0; i < len; i++) {
      const tData = applicants[i];
      // some applicationProcess is null or undefined
      const formattedDate = tData.lastUpdated
        ? `${formatTime(tData.lastUpdated)}, ${convertToLocalAndFormatDate(
            tData.lastUpdated
          )}`
        : "";
      const data = {
        fullName: tData.isSoftDelete ? (
          <>
            {`${tData.firstName || ""} ${tData.lastName || ""} `}{" "}
            <Chip color="warning" label="Removed" size="small" />
          </>
        ) : (
          (tData.firstName || "") + " " + (tData.lastName || "")
        ),
        credit: tData.fadvStatus ?? "",
        identity: tData.onfidoStatus ?? "",
        modifiedDate: formattedDate,
        alerts: tData.issues
          ? Object.entries<[string, any]>(tData.issues).map(
              ([issueType, issues]) =>
                issues.map((issue, index) => (
                  <>
                    <span
                      key={issue.id}
                      className={`alert-type-${issueType.toLocaleLowerCase()}`}
                    >
                      {issues.length > 1
                        ? `${ScreeningTypes[issueType] ?? issueType} (${
                            index + 1
                          }): `
                        : `${ScreeningTypes[issueType] ?? issueType}:`}
                    </span>
                    <span>{` ${issue.description}`}</span>
                    <br />
                  </>
                ))
            )
          : "-",
        key: `${tData.authenticationId}-${i}`,
      };
      if (
        applicants[i].applicantTypeId === ApplicantType.Applicant ||
        applicants[i].applicantTypeId === ApplicantType.CoTenant
      ) {
        arr.push(data);
      } else {
        guarantors.push(data);
      }
    }
    setApplicantData(arr);
    setGuarantorData(guarantors);
    prepColumns("Adults", arr.length);
    prepColumns("Guarantors", guarantors.length);
  };

  const showSubmitAgain = () => {
    const { applicants } = application || {};

    if (!applicants) {
      return false;
    }

    const everyoneInTwoStepScreening = applicants.every(
      ({ applicationProcess, isSoftDelete }) =>
        applicationProcess &&
        applicationProcess.applicationStageId ===
          ApplicationStage.TwoStepScreening &&
        !isSoftDelete
    );

    const hasNoFADV = applicants.every(
      ({ applicantScreening, isSoftDelete }) => {
        if (
          applicantScreening !== null &&
          applicantScreening.length > 0 &&
          !isSoftDelete
        ) {
          return applicantScreening.every((s) => {
            return (
              s.screeningVendor !== ("Fadv" as ScreeningVendor) &&
              s.screeningVendor !== "FADV"
            );
          });
        }
        return true;
      }
    );

    const hasGuarantorWithCompleteStatus = applicants.some(
      ({ applicantTypeId, applicationProcess, isSoftDelete }) =>
        applicantTypeId === ApplicantType.Guarantor &&
        applicationProcess?.applicationStatusId ===
          ApplicationStatusId.ApplicationComplete &&
        !isSoftDelete
    );

    const allApplicantsAndCoTenantsHaveCreditAndIDReturned = applicants
      .filter(
        ({ applicantTypeId, isSoftDelete }) =>
          (applicantTypeId === ApplicantType.Applicant ||
            applicantTypeId === ApplicantType.CoTenant) &&
          !isSoftDelete
      )
      .every(
        ({ applicationProcess }) =>
          applicationProcess &&
          applicationProcess.applicationStatusId ===
            ApplicationStatusId.CreditAndIDReturned
      );

    return (
      everyoneInTwoStepScreening &&
      (hasNoFADV ||
        (hasGuarantorWithCompleteStatus &&
          allApplicantsAndCoTenantsHaveCreditAndIDReturned))
    );
  };

  const getPrimaryApplicant = () => {
    if (!formattedApplication) return null;
    return formattedApplication.applicants.find(
      (x) => x.applicantTypeId === ApplicantType.Applicant
    );
  };

  const hasFadvRequestSuccess =
    !!notification &&
    notification.type === "INFO" &&
    notification?.message &&
    notification.message.includes("Credit Screening for");

  const hasCreditScreeningSuccess =
    !!notification &&
    notification.type === "INFO" &&
    notification?.message &&
    notification.message.includes(
      "Condition Met, increased security deposit met for"
    );

  const showConfirmIncreasedDepositButton =
    formattedApplication &&
    (isScreeningFlowEnabled
      ? formattedApplication.applicationScreeningResultId === 2
      : formattedApplication.applicationScreeningResult &&
        formattedApplication.applicationScreeningResult.toUpperCase() ===
          "APPROVED WITH CONDITIONS") &&
    !hasCreditScreeningSuccess;

  /**
   * NOTE: Expected values for overAllScreeningStatuses
   *  are "Pending", "Declined" and "Accepted"
   */
  return (
    <div id="avb-screening">
      <Collapse defaultActiveKey={"1"} bordered={false}>
        <Panel header={`SCREENING & ID VERIFICATION OUTCOMES`} key="1">
          <div className="row">
            <div className="blue-card col-sm-12 col-md-4">
              <h3>Group Screening Outcome</h3>
              <p>GROUP DECISION</p>
              {formattedApplication &&
                (isScreeningFlowEnabled
                  ? !!formattedApplication.applicationScreeningResultId && (
                      <div className="col tags">
                        <p className="tagPlaceHolder">
                          <StatusTag
                            name={
                              ScreeningStatusMap[
                                formattedApplication
                                  .applicationScreeningResultId
                              ]
                            }
                          />
                        </p>
                        {hasScreeningAlerts && (
                          <p className="tagPlaceHolder">
                            <StatusTag name="Screening alert" />
                          </p>
                        )}
                      </div>
                    )
                  : formattedApplication.applicationScreeningResult && (
                      <div className="col tags">
                        <p className="tagPlaceHolder">
                          <StatusTag
                            name={
                              formattedApplication.applicationScreeningResult
                            }
                          />
                        </p>
                        {hasScreeningAlerts && (
                          <p className="tagPlaceHolder">
                            <StatusTag name="Screening alert" />
                          </p>
                        )}
                      </div>
                    ))}
              <p>
                TIME RECEIVED
                <span>{latestDate}</span>
              </p>
              {!hasFadvRequestSuccess && showSubmitAgain() && (
                <button
                  className="submit-again"
                  onClick={() => setShowCreditScreeningModal(true)}
                >
                  <SubmitAgainIcon /> Submit again
                </button>
              )}
              {showConfirmIncreasedDepositButton && (
                <button
                  className="increase-deposit"
                  onClick={() => setShowConfirmIncreasedDepositModal(true)}
                >
                  <DollarIcon /> Confirm Increased Deposit Paid
                </button>
              )}
            </div>
            <div className=" col-sm-12 col-md-8">
              <Table
                dataSource={applicantData}
                columns={applicantColumns}
                pagination={false}
              />
              <Table
                dataSource={guarantorData}
                columns={guarantorColumns}
                pagination={false}
              />
            </div>
          </div>
        </Panel>
      </Collapse>
      <SubmitCreditScreeningModal
        applicationId={
          formattedApplication && formattedApplication.applicationId
        }
        applicant={getPrimaryApplicant()}
        showModal={showCreditScreeningModal}
        setShowModal={setShowCreditScreeningModal}
      />
      {formattedApplication && (
        <ConfirmIncreasedDepositModal
          application={formattedApplication}
          showModal={showConfirmIncreasedDepositModal}
          setShowModal={setShowConfirmIncreasedDepositModal}
        />
      )}
    </div>
  );
};

export default Screening;
