import { useState, useEffect, useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { postLease } from "../../services/AdminDashService";
import { Application, LeaseEventId } from "../../types/Application";

interface Props {
  selectedApplication: Application | undefined;
  onClose(): void;
  show: boolean;
}

const GenerateLeaseModal = ({ selectedApplication, onClose, show }: Props) => {
  const dispatch = useAppDispatch();
  const notification = useAppSelector(
    (state) => state.applications.notification
  );
  const [disableSendButton, setDisableSendButton] = useState(false);
  useEffect(() => {
    setDisableSendButton(false);
    if (
      notification &&
      notification.message &&
      notification.type === "INFO" &&
      notification.message.endsWith(
        "Lease has been generated and successfully sent out"
      )
    ) {
      onClose();
    }
  }, [notification]);

  const generateLease = useCallback(() => {
    setDisableSendButton(true);
    dispatch(
      postLease(
        (selectedApplication as Application).applicationId,
        LeaseEventId.LeaseGenerated
      )
    );
  }, [setDisableSendButton, selectedApplication?.applicationId]);

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      className="generate-lease-modal"
    >
      <Modal.Header>
        <Modal.Title>
          <span className="cancel-icon">!</span>Generate Lease?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="details">
          <div className="row">
            <div className="col">
              Are you sure you want to Generate Lease and email it to all
              Applicants?
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={onClose}
          disabled={disableSendButton}
        >
          Cancel
        </Button>
        <Button
          data-test-id="send-lease-button"
          variant="dark"
          onClick={generateLease}
          disabled={disableSendButton}
        >
          OK to Send
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GenerateLeaseModal;
