// Admin Dashboard API
export interface Addendums {
  forms?: AddendumsForm[];
  videos?: AddendumsVideos;
}
export interface AddendumsVideos {
  [videoKey: string]: string | undefined; // videoEnglish: "https://www.youtube.com/shorts/mjNBwkjWRDI"
  videoEnglish?: string;
  videoSpanish?: string;
}

export interface AddendumsForm {
  formName?: string; // "Window Guard"
  description?: string; // "Your landlord is required by law to install window guards on any window in your apartment that can be opened and is located above ground floor if a child 10 years of age or younger lives in your apartment, or if you ask your landlord to put in window guards at any time (you do not need to give a reason)."
  disclaimer?: string | null; // "Note: It is a violation to refuse, interfere with installation, or remove window guards where required and where they have been installed. Failure to do so would prompt an inspection by the landlord."
  questions?: AddendumsQuestion[];
  responses?: AddendumsResponse[];
}

export interface AddendumsPost {
  /** @format int64 */
  id?: number;
  forms?: AddendumsPostAddendums[];
}

export interface AddendumsPostAddendums {
  formName?: string;
  responses?: AddendumsResponse[];
}

export interface AddendumsAnswers {
  id?: number; // 4
  answerText?: string; // "At least one child 10 years old or younger currently lives in my apartment."
  isRequired?: boolean;
  sortOrder: number;
}

export interface AddendumsQuestion {
  id?: number;
  questionText?: string;
  questionType?: QuestionType;
  sortOrder?: number | null;
  isRequired?: boolean;
  minAnswers?: number | null;
  answers?: AddendumsAnswers[];
}

export enum QuestionType {
  SingleLine = "single-line",
  MultiLine = "multi-line",
  SingleSelect = "single-select",
  MultiSelect = "multi-select",
}

export interface AddendumsResponse {
  answerId?: number;
  answerValue?: string;
}

export enum AddendumsAnswerId {
  MontgomeryChildUnder10 = 4,
  MontgomeryWindowGuardInstalled = 5,
  YonkersWindowGuardChildUnder10 = 9,
  YonkersWindowGuardNoChildUnder10 = 10,
  YonkersWindowGuardInstalled = 11,
  EmContactFirstName = 12,
  EmContactEmail = 14,
  EmContactPhone = 15,
  NYCWindowGuardChildUnder10 = 16,
  NYCWindowGuardNoChildUnder10 = 17,
  NYCWindowGuardInstalled = 18,
  NYCStoveKnobCovers = 19,
  NYCStoveKnobsPermanent = 20,
  NYCChildUnder6 = 21,
}

export const MapAssociateActionToAnswerIds = {
  "N/A": [
    AddendumsAnswerId.YonkersWindowGuardNoChildUnder10,
    AddendumsAnswerId.EmContactFirstName,
    AddendumsAnswerId.NYCWindowGuardNoChildUnder10,
  ],
  "Enter a Maintenance Request in SightPlan.": [
    AddendumsAnswerId.MontgomeryChildUnder10,
    AddendumsAnswerId.MontgomeryWindowGuardInstalled,
    AddendumsAnswerId.YonkersWindowGuardChildUnder10,
    AddendumsAnswerId.YonkersWindowGuardInstalled,
    AddendumsAnswerId.NYCWindowGuardChildUnder10,
    AddendumsAnswerId.NYCWindowGuardInstalled,
    AddendumsAnswerId.NYCStoveKnobCovers,
    AddendumsAnswerId.NYCStoveKnobsPermanent,
    AddendumsAnswerId.NYCChildUnder6,
  ],
};
