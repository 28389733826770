import { useCallback, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import Select from "react-select";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {
  setSearchTerms,
  setFilterOptions,
  setApplications,
} from "src/store/features/applications/applicationsSlice";
import {
  getApplications,
  getFilterOptions,
  updateUserFilterSelection,
} from "../../services/AdminDashService";
import { Form } from "react-bootstrap";
import SearchIcon from "../Shared/icons/SearchIcon";
import {
  filterCommunities,
  filterMarkets,
  getPhases,
  selections2Options,
  options2String,
  formatOptions,
} from "../../utils/Filter";
import {
  GetFilterOptionsBody,
  UserFilterSelections,
} from "src/types/FilterOptions";
import { getConfigs } from "src/configs/Config";
require("bootstrap/dist/css/bootstrap.css");

export const Filters = () => {
  const { refetchDataIntervalTime } = getConfigs();
  const filterOptions = useAppSelector(
    (state) => state.applications.filterOptions
  );
  const refetchTimerId = useRef<undefined | NodeJS.Timer>(undefined);

  const dispatch = useAppDispatch();

  const fetchApplications = useCallback(() => {
    const { userFilterSelections } = filterOptions as GetFilterOptionsBody;
    const isNonFirstTimeVisitor =
      filterOptions && userFilterSelections && !!userFilterSelections.id;
    const hasCommunitiesSelections = !!userFilterSelections.communities;
    const hasMarketSelections = !!userFilterSelections.markets;
    const hasRegionSelections = !!userFilterSelections.regions;

    const hasNoSelections =
      !hasCommunitiesSelections && !hasMarketSelections && !hasRegionSelections;

    // ignore first-time visitor w/o selections
    if (!isNonFirstTimeVisitor && hasNoSelections) return;

    if (hasNoSelections) {
      dispatch(setApplications({ applications: [], count: 0 }));

      return;
    }

    if (hasCommunitiesSelections) {
      const communitySelections = userFilterSelections.communities.split(",");
      const phases = getPhases(filterOptions, communitySelections);

      if (phases.length > 0) dispatch(getApplications(phases));
      else dispatch(setApplications({ applications: [], count: 0 }));

      return;
    }

    if (hasMarketSelections || hasRegionSelections) {
      const communities = filterCommunities(
        filterOptions as GetFilterOptionsBody
      );
      const communityValues = communities.map((x) => x.value);
      const phases = getPhases(filterOptions, communityValues);

      if (phases.length > 0) dispatch(getApplications(phases));
      else dispatch(setApplications({ applications: [], count: 0 }));

      return;
    }
  }, [filterOptions]);

  useEffect(() => {
    dispatch(getFilterOptions());
  }, []);

  useEffect(() => {
    if (!filterOptions) return;
    fetchApplications();
  }, [filterOptions]);

  // Refetch data when window is on focus
  useEffect(() => {
    const initRefetchTimer = () => {
      if (refetchTimerId.current) {
        clearInterval(refetchTimerId.current);
      }
      refetchTimerId.current = setInterval(() => {
        if (!filterOptions) return;
        fetchApplications();
      }, refetchDataIntervalTime);
    };

    const stopRefetchTimer = () => {
      if (refetchTimerId.current) {
        clearInterval(refetchTimerId.current);
        refetchTimerId.current = undefined;
      }
    };

    const visibilitychange = () => {
      if (document.hidden) {
        stopRefetchTimer();
      } else {
        initRefetchTimer();
      }
    };

    initRefetchTimer();
    document.addEventListener("visibilitychange", visibilitychange);
    window.addEventListener("focus", initRefetchTimer);
    window.addEventListener("blur", stopRefetchTimer);

    return () => {
      stopRefetchTimer();
      document.removeEventListener("visibilitychange", visibilitychange);
      window.removeEventListener("focus", initRefetchTimer);
      window.removeEventListener("blur", stopRefetchTimer);
    };
  }, [filterOptions, fetchApplications]);

  const customCommunityFilter = (option, searchText = "") => {
    const {
      value = "",
      label = "",
      data: { customValue = "" },
    } = option;
    searchText = searchText.toLowerCase();
    return (
      value.toLowerCase().includes(searchText) ||
      label.toLowerCase().includes(searchText) ||
      customValue.toLowerCase().includes(searchText)
    );
  };

  const onFilterOptionChange = (e, filterType) => {
    const stringSelections = options2String(e);
    const selectedUserFilters = { ...filterOptions.userFilterSelections };
    selectedUserFilters[filterType] = stringSelections;
    // force non-returning visitor status - data is never used
    selectedUserFilters["id"] = "<refresh-to-update>";

    dispatch(updateUserFilterSelection(selectedUserFilters));
    dispatch(setFilterOptions(selectedUserFilters as UserFilterSelections));
  };

  const onSearchTerms = (e) => {
    dispatch(setSearchTerms(e.target.value));
  };

  return (
    <div id="avb-filters">
      <Row>
        <Col id="avb-filters-unit-application-name">
          <div>
            <SearchIcon className="search-icon" />
            <Form.Control
              className="search-input"
              type="text"
              placeholder="Unit or Applicant Name"
              onChange={onSearchTerms}
              disabled={!filterOptions}
            />
          </div>
        </Col>
        <Col style={{}} id="avb-filters-region">
          <Select
            placeholder="Region"
            instanceId="regions"
            value={filterOptions && selections2Options(filterOptions, "region")}
            isMulti
            options={
              filterOptions &&
              formatOptions(
                (filterOptions as GetFilterOptionsBody).regions,
                "region"
              )
            }
            onChange={(e) => onFilterOptionChange(e, "regions")}
            isDisabled={!filterOptions}
          />
        </Col>
        <Col style={{}} id="avb-filters-market">
          <Select
            placeholder="Market"
            instanceId="markets"
            value={filterOptions && selections2Options(filterOptions, "market")}
            isMulti
            options={filterOptions && filterMarkets(filterOptions)}
            onChange={(e) => onFilterOptionChange(e, "markets")}
            isDisabled={!filterOptions}
          />
        </Col>
        <Col style={{}} id="avb-filters-community">
          <Select
            isMulti
            value={
              filterOptions && selections2Options(filterOptions, "community")
            }
            placeholder="Community"
            instanceId="communities"
            options={
              filterOptions &&
              filterCommunities(filterOptions as GetFilterOptionsBody)
            }
            filterOption={customCommunityFilter}
            onChange={(e) => onFilterOptionChange(e, "communities")}
            isDisabled={!filterOptions}
            aria-labelledby="Communities filter"
          />
        </Col>
      </Row>
    </div>
  );
};

export default Filters;
