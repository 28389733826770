export const getNotificationMessageForApplicant = (application, applicant) => {
  const unitIdArray = application.unitId.split("-");
  const apartmentNumber = unitIdArray[unitIdArray.length - 1];

  switch (applicant.status.toLowerCase()) {
    case "Cancelled".toLowerCase():
      return {
        subject: "Your Avalon Bay Apartment Application has been cancelled",
        body: `Dear ${applicant.firstName},

It’s the team at ${application.communityName}. Your application for apartment ${apartmentNumber} at ${application.communityName} has been canceled.

If you would like to re-apply for one of our apartment homes, please visit https://www.avaloncommunities.com and login to continue.
            
            `,
      };

    case "Invite Sent".toLowerCase():
      return {
        subject:
          "You have been invited to join an AvalonBay Apartment Application!",
        body: `Dear ${applicant.firstName},

It’s the team at ${application.communityName}. You previously have been sent an invitation by one of your co-applicants to complete an application for apartment ${apartmentNumber} at ${application.communityName}. 

Please use the link in that invitation to complete your application as soon as possible.  If you need the invitation to be re-sent, please ask your co-applicant to log into their applicant dashboard and click the notification bell to resend the invite link.

Apartment ${apartmentNumber} will remain on hold for 24 hours giving you and all other people in your group time to complete your application(s) and submit payment. All application for your group must be completed and submitted with full payment within 24 hours or the hold will be released to available inventory. When a hold is released, the apartment is accessible on the AvalonBay Communities website for anyone to apply, and pricing is subject to change daily.
            
Thank you
            
            `,
      };

    case "In Progress".toLowerCase():
      return {
        subject: "ACTION REQUIRED:  Finish your apartment application",
        body: `Hi ${applicant.firstName},

It’s the team at ${application.communityName}. We can see you started your application for Apartment ${apartmentNumber} but have not completed it. As a reminder you have limited time left to complete your application before the apartment is released, so please log in to your applicant dashboard and take the necessary actions as soon as possible so you do not miss out!

Applicant Dashboard:
https://www.avaloncommunities.com/login/

If you need any assistance, please review the FAQs or click the “I need help” button in your applicant dashboard. 
            
            `,
      };

    case "Action Required".toLowerCase():
      return {
        subject: "ACTION REQUIRED:  Finish your apartment application",
        body: `Hi ${applicant.firstName},

It’s the team at ${application.communityName}. We can see you started your application for Apartment ${apartmentNumber}, action is required to complete it. As a reminder you have limited time left to complete your application before the apartment is released, so please log in to your applicant dashboard and take the necessary actions as soon as possible so you do not miss out!

Applicant Dashboard:
https://www.avaloncommunities.com/login/

If you need any assistance, please review the FAQs or click the “I need help” button in your applicant dashboard.
            
            `,
      };

    case "Application Complete".toLowerCase():
      return {
        subject: `Application Complete: ${application.communityName}`,
        body: `Hi ${applicant.firstName},

It’s the team at ${application.communityName}. Thank you for completing your application for Apartment ${apartmentNumber}. Once all occupants in your group (if applicable) completed their applications Credit and ID screening will begin. We will provide additional notifications related to your application status. No further action is required at this time.  

Thank you!

            `,
      };

    case "Credit and ID Pending".toLowerCase():
      return {
        subject: `Application Status Update: ${application.communityName}`,
        body: `Hi ${applicant.firstName},

It’s the team at ${application.communityName}. Your application status for Apartment ${apartmentNumber} has been updated to Credit and ID Pending. No further action is required at this time.  
            
Thank you!
            
            `,
      };

    case "Credit and ID Returned".toLowerCase():
      return {
        subject: `Application Status Update: ${application.communityName}`,
        body: `Hi ${applicant.firstName},

It’s the team at ${application.communityName}. Your application status for Apartment ${apartmentNumber} has been updated to Credit and ID Returned.  

Please log into your application dashboard to view your screening outcome and take any necessary next steps.

Applicant Dashboard:
https://www.avaloncommunities.com/login/

If you need any assistance, please review the FAQs or click the “I need help” button in your applicant dashboard. 
            
Thank you!
            
            `,
      };

    default:
      return {
        subject: "",
        body: "",
      };
  }
};
