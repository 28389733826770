import { Tag } from "antd";
import { EditFilled, ExclamationOutlined } from "@ant-design/icons";
import EllipsisIcon from "./icons/EllipsisIcon";
import InvitationIcon from "./icons/InvitationIcon";
import CreditReturnedIcon from "./icons/CreditReturnedIcon";
import ApprovedIcon from "./icons/ApprovedIcon";
import CompletionIcon from "./icons/CompletionIcon";
import CreditPendingIcon from "./icons/CreditPendingIcon";
import CancelIcon from "./icons/CancelIcon";
import ExclamationIcon from "./icons/ExclamationIcon";
import PendingAddendumsIcon from "./icons/PendingAddendums";

const StatusTag = ({ name }) => {
  if (!name) return null;
  // remove all spaces, parens, and exclamation marks from `name`
  const statusName = name.replace(/[\s\(\)!]/g, "").toUpperCase();
  switch (statusName) {
    case "APPLICATIONCOMPLETE":
      return (
        <div>
          <span title={name}>
            <Tag color={"green"} icon={<CompletionIcon />}>
              {name}
            </Tag>
          </span>
        </div>
      );
    case "ACCEPTEDWITHCONDITIONS":
      return (
        <div>
          <span title={name}>
            <Tag color={"orange"} icon={<ExclamationOutlined />}>
              {name}
            </Tag>
          </span>
        </div>
      );
    case "DECLINED":
      return (
        <div>
          <span title={name}>
            <Tag color={"volcano"} icon={<CancelIcon />}>
              {name}
            </Tag>
          </span>
        </div>
      );
    case "CANCELLED":
      return (
        <div>
          <span title={name}>
            <Tag color={"volcano"} icon={<CancelIcon />}>
              {name}
            </Tag>
          </span>
        </div>
      );
    case "EXPIRED":
      return (
        <div>
          <span title={name}>
            <Tag color={"volcano"} icon={<CancelIcon />}>
              ...{name}
            </Tag>
          </span>
        </div>
      );
    case "SCREENINGRETURNED":
    case "CREDITANDIDRETURNED":
      return (
        <div>
          <span title={name}>
            <Tag className="credit-pending" icon={<CreditReturnedIcon />}>
              Screening Returned
            </Tag>
          </span>
        </div>
      );
    case "PENDING":
    case "SCREENINGPENDING":
    case "CREDITANDIDPENDING":
      return (
        <div>
          <span title={name}>
            <Tag className="credit-pending" icon={<CreditPendingIcon />}>
              Screening Pending
            </Tag>
          </span>
        </div>
      );
    case "INPROGRESS":
      return (
        <div className="tag-in-progress">
          <span title={name}>
            <Tag color={"blue"} icon={<EllipsisIcon />}>
              {name}
            </Tag>
          </span>
        </div>
      );
    case "PROBLEMRESOLUTION":
      return (
        <div className="tag-in-problem-resolution">
          <span title={name}>
            <Tag color={"purple"} icon={<EllipsisIcon />}>
              {name}
            </Tag>
          </span>
        </div>
      );
    case "INVITESENT":
      return (
        <div>
          <span title={name}>
            <Tag color={"orange"} icon={<InvitationIcon />}>
              {name}
            </Tag>
          </span>
        </div>
      );
    case "APPROVED":
      return (
        <div>
          <span title={name}>
            <Tag color={"green"} icon={<ApprovedIcon />}>
              {name}
            </Tag>
          </span>
        </div>
      );
    case "FURTHERREVIEW":
      return (
        <div className="tag-further-review">
          <span title={name}>
            <Tag color={"blue"} icon={<EllipsisIcon />}>
              {name}
            </Tag>
          </span>
        </div>
      );
    case "APPROVEDWITHCONDITIONS":
      return (
        <div className="">
          <span title={name}>
            <Tag color={"orange"} icon={<ExclamationIcon />}>
              &nbsp;&nbsp;Approved with conditions
            </Tag>
          </span>
        </div>
      );
    case "APPROVEDWITHCONDITIONSCONDITIONSMET":
      return (
        <div className="text-truncate">
          <span title={name}>
            <Tag color={"orange"} icon={<ExclamationIcon />}>
              &nbsp;&nbsp;AWC, conditions met
            </Tag>
          </span>
        </div>
      );
    case "LEASEREADY":
      return (
        <div>
          <span>
            <Tag className="credit-pending" icon={<CreditPendingIcon />}>
              Pending Lease Generation
            </Tag>
          </span>
        </div>
      );
    case "LEASEPENDING":
      return (
        <div>
          <span>
            <Tag
              color={"orange"}
              icon={
                <EditFilled
                  style={{
                    color: "orange",
                  }}
                />
              }
            >
              Pending Signature
            </Tag>
          </span>
        </div>
      );
    case "LEASESIGNED":
      return (
        <div>
          <span title={name}>
            <Tag color={"green"} icon={<ApprovedIcon />}>
              {name}
            </Tag>
          </span>
        </div>
      );
    case "LEASEEXPIRED":
      return (
        <div>
          <span title={name}>
            <Tag color={"volcano"} icon={<CancelIcon />}>
              Document Expired
            </Tag>
          </span>
        </div>
      );
    case "DOCUMENTEXPIRED":
      return (
        <div>
          <span title={name}>
            <Tag color={"volcano"} icon={<CancelIcon />}>
              Document Expired
            </Tag>
          </span>
        </div>
      );
    case "LEASEDECLINED":
      return (
        <div>
          <span title={name}>
            <Tag color={"volcano"} icon={<CancelIcon />}>
              {name}
            </Tag>
          </span>
        </div>
      );
    case "PENDINGADDENDUMRESPONSE":
    case "PENDINGADDENDUMS":
      return (
        <div>
          <span>
            <Tag className="credit-pending" icon={<PendingAddendumsIcon />}>
              Pending Addendums
            </Tag>
          </span>
        </div>
      );
    case "PENDINGADDENDUMSELECTION":
      return (
        <div>
          <span>
            <Tag className="credit-pending" icon={<CreditPendingIcon />}>
              Pending Addendum Selection
            </Tag>
          </span>
        </div>
      );
    case "SCREENINGALERT":
      return (
        <div className="">
          <span title={name}>
            <Tag color={"orange"} icon={<ExclamationIcon />}>
              &nbsp;&nbsp;{name}
            </Tag>
          </span>
        </div>
      );
    case "ACTIONREQUIRED":
      return (
        <div>
          <span title={name}>
            <Tag color={"yellow"} icon={<ExclamationIcon />}>
              {name}
            </Tag>
          </span>
        </div>
      );
    case "REMOVED":
      return null;
    default:
      return (
        <Tag key={name} color={"blue"} icon={<ExclamationOutlined />}>
          {name}
        </Tag>
      );
  }
};

export default StatusTag;
