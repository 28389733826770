const getRemainingTimeInMs = (expirationDate: Date) =>
  expirationDate.getTime() - Date.now();
const padZeros = (num: number, places = 2) => String(num).padStart(places, "0");
const getHoldClockColor = (timeInMs: number) => {
  const greenTime = 6 * 60 * 60 * 1000 + 1000;
  const yellowTime = 3 * 60 * 60 * 1000 + 1000;
  if (timeInMs >= greenTime) return "green";
  if (timeInMs >= yellowTime) return "yellow";
  return "red";
};
const getTimeColor = (timeInMs: number) => (timeInMs <= 0 ? "zero" : "");

const getTimeParts = (timeInMs: number) => {
  const hours = Math.floor(timeInMs / 1000 / 60 / 60);
  const minutes = Math.floor(timeInMs / 1000 / 60) % 60;
  const seconds = Math.floor(timeInMs / 1000) % 60;

  const timeParts = [hours, minutes, seconds];
  return timeParts.map((num) => Math.max(num, 0)).map((num) => padZeros(num));
};

/**
 * This functions receives ms to calculate if it's expired
 * @param {number} remainingTime
 * @returns {boolean}
 */
const isClockExpired = (remainingTime: number) => remainingTime <= 0;

/**
 * This function validates if an application has a valid screening result
 * @param {string} applicationScreeningResult
 * @returns {boolean}
 */
const isApplicationScreeningResultValid = (applicationScreeningResult) => {
  const excludeStatus = [
    "PENDING",
    "CREDIT AND ID PENDING",
    "DECLINED",
    "FURTHER REVIEW",
    "PROBLEM RESOLUTION",
    "APPROVED WITH CONDITIONS",
    "SCREEENING PENDING",
  ];
  if (
    applicationScreeningResult != null &&
    applicationScreeningResult != "" &&
    excludeStatus.indexOf(applicationScreeningResult.toUpperCase()) > -1
  ) {
    return false;
  }
  return true;
};

const APPLICATION_HOLD_APP_CANCELLED = 3;
const isApplicationStatusExpired = (statusName, application) => {
  const { applicationHold, applicationScreeningResult } = application;

  if (applicationHold && applicationHold.holdDate) {
    // expected when associate cancels the application
    if (applicationHold.updateEventId === APPLICATION_HOLD_APP_CANCELLED)
      return false;

    // expected when the clock naturally expires
    const holdDateFormatted = new Date(applicationHold.holdDate);
    const remainingTime = getRemainingTimeInMs(holdDateFormatted);
    if (
      applicationScreeningResult == "" &&
      isClockExpired(remainingTime) &&
      statusName == "Cancelled"
    ) {
      return true;
    }
  }

  // Is not a hold application, thus cannot expire
  return false;
};

export {
  getRemainingTimeInMs,
  padZeros,
  getHoldClockColor,
  getTimeColor,
  getTimeParts,
  isClockExpired,
  isApplicationStatusExpired,
  isApplicationScreeningResultValid,
};
