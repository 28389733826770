require("./index.css");
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import packageJson from "../package.json";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import store from "./redux/store";

// Google Analytics 4
const gA4Args = [
  {
    trackingId: `${process.env.REACT_APP_GA4_TAG}`,
  },
];
ReactGA.initialize(gA4Args);
ReactGA.send("pageview");

// Google Tag Manager
const tagManagerArgs = {
  gtmId: `${process.env.REACT_APP_GTM_TAG}`,
};
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    {console.log(
      `${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION} - ${packageJson.version}`
    )}
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
