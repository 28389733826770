import React from "react";

const MagnifierGlassIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="68"
    height="68"
    viewBox="0 0 68 68"
  >
    <path
      d="m67.002 63.167-15.468-15.46a28.757 28.757 0 0 0 6.633-18.415C58.167 13.342 45.24.417 29.292.417 13.342.417.417 13.343.417 29.292s12.926 28.875 28.875 28.875c7 0 13.415-2.49 18.415-6.633l15.468 15.468c.526.526 1.218.79 1.917.79.7 0 1.384-.264 1.918-.79a2.73 2.73 0 0 0-.008-3.835zM38.42 50.91a23.216 23.216 0 0 1-9.128 1.843c-3.174 0-6.242-.617-9.129-1.843a23.407 23.407 0 0 1-7.46-5.03 23.407 23.407 0 0 1-5.03-7.46 23.216 23.216 0 0 1-1.842-9.128c0-3.174.616-6.242 1.842-9.129a23.407 23.407 0 0 1 5.03-7.46 23.407 23.407 0 0 1 7.46-5.03 23.216 23.216 0 0 1 9.129-1.842c3.173 0 6.24.616 9.128 1.842a23.407 23.407 0 0 1 7.46 5.03 23.407 23.407 0 0 1 5.03 7.46 23.216 23.216 0 0 1 1.843 9.129c0 3.173-.617 6.24-1.843 9.128a23.407 23.407 0 0 1-5.03 7.46 23.324 23.324 0 0 1-7.46 5.03z"
      fill="#DCDDDF"
      fillRule="evenodd"
    />
  </svg>
);

export default MagnifierGlassIcon;
