require("./App.scss");
import { Fragment, useEffect } from "react";
import IdleTimer from "react-idle-timer";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import MainWrapper from "./components/Shared/MainWrapper";
import AdminDashboard from "./components/Pages/AdminDashboard";
import ApplicationDetails from "./components/Pages/ApplicationDetails";
import Login from "./components/Pages/Login";
import PrivateRoute from "./components/Shared/PrivateRoute";
import CognitoRedirectUri from "./components/Shared/CognitoRedirectUri";
import AuthService from "./services/AuthService";
import { getConfigs } from "./configs/Config";
import { isAuthenticated, storeCredentials } from "./utils/Auth";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

function App() {
  let timer: any = null;
  const { maxIdleTime, minTokenTimeLeft } = getConfigs();

  useEffect(() => {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    const formatted =
      environment === "dev"
        ? " - Dev"
        : environment === "qa"
        ? " - QA"
        : environment === "stage"
        ? " - Stage"
        : "";

    document.title = `AvalonBay Admin Dashboard${formatted}`;
  }, []);

  const logout = () => {
    sessionStorage.clear();
    document.location = "/AdminDashboard/Login";
  };

  const refreshToken = () => {
    AuthService.refreshToken()
      .then((response) => response.json())
      .then((response) => {
        storeCredentials(response);
      })
      .catch((e) => {
        console.log("Error refreshing token...", e);
        logout();
      });
  };

  const handleIdle = () => {
    console.log(`User was idle for ${maxIdleTime}ms. Logging out...`);
    sessionStorage.clear();
    document.location = "/AdminDashboard/Login";
  };

  // Authentication tokens will be auto renewed when expiry is reached
  const onAction = () => {
    if (!isAuthenticated()) return;
    const remainingTime = timer.getRemainingTime();
    if (minTokenTimeLeft > remainingTime) {
      refreshToken();
    }
  };

  return (
    <div className="App">
      <IdleTimer
        timeout={maxIdleTime}
        ref={(ref) => {
          timer = ref;
        }}
        throttle={30000}
        element={document}
        onIdle={handleIdle}
        startOnMount={true}
        onAction={onAction}
      />
      <link rel="preconnect" href="https://www.googletagmanager.com"></link>
      <MainWrapper>
        <Router>
          <Fragment>
            <Routes>
              <Route path="/AdminDashboard/" element={<PrivateRoute />}>
                <Route
                  path="/AdminDashboard/Application-Details/:id"
                  element={<ApplicationDetails />}
                />
                <Route
                  path="/AdminDashboard/Applications"
                  element={<AdminDashboard />}
                />
              </Route>
              <Route path="/AdminDashboard/Login" element={<Login />} />
              <Route
                path="/AdminDashboard/Login-Success"
                element={<CognitoRedirectUri />}
              />
              <Route
                path="*"
                element={<Navigate to="/AdminDashboard/Login" replace />}
              />
            </Routes>
          </Fragment>
        </Router>
      </MainWrapper>
    </div>
  );
}

export default App;
