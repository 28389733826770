const CreditPendingIcon = () => {
  return (
    <span role="img" className="anticon">
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.945 6h.02a1 1 0 0 1 .854 1.574l-.05.066-.753.935A3.246 3.246 0 0 0 6 9.25c1.061 0 2.035-.51 2.642-1.356a1 1 0 0 1 1.625 1.166A5.244 5.244 0 0 1 6 11.25a5.242 5.242 0 0 1-3.24-1.118l-.487.605a1 1 0 0 1-1.74-.405.865.865 0 0 1-.03-.173L.5 10.09V6.909a.901.901 0 0 1 .256-.63l.018-.018a.915.915 0 0 1 .08-.068l.036-.027a.924.924 0 0 1 .282-.133l.055-.013a.929.929 0 0 1 .124-.018L1.42 6h2.525zM6 .75a5.24 5.24 0 0 1 3.031.963l.41-.438a1 1 0 0 1 1.682.234c.049.102.08.214.09.332l.002.068.002 3.047a.914.914 0 0 1-.841 1.041l-.07.003H7.296a.923.923 0 0 1-.687-.305l.079.073a1 1 0 0 1-.178-1.343l.05-.065 1.086-1.164A3.244 3.244 0 0 0 6 2.75a3.244 3.244 0 0 0-2.644 1.359 1 1 0 1 1-1.625-1.165A5.244 5.244 0 0 1 6 .75z"
          fill="#72767F"
          fillRule="evenodd"
        />
      </svg>
    </span>
  );
};
export default CreditPendingIcon;
