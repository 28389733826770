export enum ScreeningStatusId {
  Other = 0,
  Approved = 1,
  ApprovedWithConditions,
  Declined,
  FurtherReview,
  Pending,
  GuarantorRequired,
  DocumentSubmissionCompleted,
  Retry,
  ProblemResolution,
  ApprovedWithConditionsMet,
}

export const ScreeningStatusMap = {
  0: "Other",
  1: "Approved",
  2: "Approved With Conditions",
  3: "Declined",
  4: "Further Review",
  5: "Pending",
  6: "Guarantor Required",
  7: "Document Submission Completed",
  8: "Retry",
  9: "Problem Resolution",
  10: "Approved With Conditions (Conditions Met)",
};

export enum ScreeningStatus {
  Other = "Other",
  Approved = "Approved",
  ApprovedWithConditions = "ApprovedWithConditions",
  Declined = "Declined",
  FurtherReview = "FurtherReview",
  Pending = "Pending",
  Retry = "Retry",
  ProblemResolution = "ProblemResolution",
  ApprovedWithConditionsMet = "ApprovedWithConditionsMet",
}

export const MapScreeningEnumTypeToScreeningStatusId = {
  Approved: ScreeningStatusMap[ScreeningStatusId.Approved],
  ApprovedWithConditions:
    ScreeningStatusMap[ScreeningStatusId.ApprovedWithConditions],
  Declined: ScreeningStatusMap[ScreeningStatusId.Declined],
  FurtherReview: ScreeningStatusMap[ScreeningStatusId.FurtherReview],
  Pending: ScreeningStatusMap[ScreeningStatusId.Pending],
  GuarantorRequired: ScreeningStatusMap[ScreeningStatusId.GuarantorRequired],
  DocumentSubmissionCompleted:
    ScreeningStatusMap[ScreeningStatusId.DocumentSubmissionCompleted],
  Retry: ScreeningStatusMap[ScreeningStatusId.Retry],
  ProblemResolution: ScreeningStatusMap[ScreeningStatusId.ProblemResolution],
  ApprovedWithConditionsMet:
    ScreeningStatusMap[ScreeningStatusId.ApprovedWithConditionsMet],
  Other: ScreeningStatusMap[ScreeningStatusId.Other],
};

export interface Issue {
  id: number;
  applicantScreeningId: number;
  type: string;
  description: string;
}

export const ScreeningTypes = {
  ssn: "SSN",
  address: "Address",
};

export const ScreeningDocumentResult = {
  clean: "Clean",
  insufficientDocumentation: "Insufficient Documentation",
  edited: "Edited",
  resubmittedClean: "Resubmitted - Clean",
  resubmittedInsufficient: "Resubmitted - Insufficient",
  resubmittedEdited: "Resubmitted - Edited",
  pendingDocumentationVerification: "Pending Document Verification",
};
